import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledHallOfChampionsFeature } from "./style";

const HallOfChampionsFeature: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledHallOfChampionsFeature>
      <div className="title">
        <div className="line1">{t("hallOfChampions:feature.line1")}</div>
        &nbsp;
        <div className="line2">
          {t("hallOfChampions:feature.line2")}
          <StaticImage
            className="logo"
            src="../../../images/champions-queue-logo-feature-victory.svg"
            alt={t("nav.logo")}
          />
        </div>
      </div>
    </StyledHallOfChampionsFeature>
  );
};

export default HallOfChampionsFeature;
